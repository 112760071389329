<template>
  <router-view/>
  <footer>
    СДЕЛАНО УЧЕНИКАМИ 12И (МАТИНФА) И УЧЕНИКОМ 10Т
  </footer>
</template>

<style scoped>
img {
  width: 500px;
  opacity: 0.8;
}
</style>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
footer {
  margin-top: 20px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>